/*.MuiPopover-root{*/
/*    !*z-index: 99999!important;*!*/
/*}*/
.switch {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 21px;
  }
.switchInput {
    width: 0px;
    height: 0px;
    opacity: 0;
}
.switchSlider {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 30px;
    background-color: #FCFCFC;
    transition: 0.4s;
    box-shadow: 0px 0px 2px;
}

.switchSlider::before {
    content: '';
    position: absolute;
    cursor: pointer;
    left: 2px;
    bottom: 2px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    transition: 0.4s;
    background-color: #FCFCFC;
    box-shadow: 0px 0px 2px;
}

.switchInput:focus + .switchSlider {
    box-shadow: 0 0 4px black;
}

.switchInput:checked + .switchSlider {
    background-color: #00D256;
}

.switchInput:checked + .switchSlider::before {
    transform: translateX(19px);
}