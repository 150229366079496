@font-face {
  font-family: "Work Sans";
  font-weight: 100;
  src: url('./assets/fonts/WorkSans-Thin.otf') format('opentype');
}
@font-face {
  font-family: "Work Sans";
  font-weight: 200;
  src: url('./assets/fonts/WorkSans-ExtraLight.otf') format('opentype');
}
@font-face {
  font-family: "Work Sans";
  font-weight: 300;
  src: url('./assets/fonts/WorkSans-Light.otf') format('opentype');
}
@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  src: url('./assets/fonts/WorkSans-Regular.otf') format('opentype');
}
@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  src: url('./assets/fonts/WorkSans-Medium.otf') format('opentype');
}
@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url('./assets/fonts/WorkSans-SemiBold.otf') format('opentype');
}
@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  src: url('./assets/fonts/WorkSans-Bold.otf') format('opentype');
}

/* * {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
} */

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #d4d4d4;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
}

::-webkit-scrollbar-thumb {
    background: #939393;
    border-radius: 8px;
    border: 3px solid #d4d4d4;
}

::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
}



input, table {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
}


.vid {
    word-break: break-word; 
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
    width: '100%';
    background: 'transparent';
    border: 'none';
    outline: 'none';
}

:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 10px;
	--PhoneInputCountrySelectArrow-width: 8px;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 30px;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	display: flex;
	align-items: center;
  	margin: 25px 0px 39px 0px;
}

.PhoneInputInput {
  width: 326px;
  height: 44px;
  border: 1px solid #D3D1CB;
  outline: none !important;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 14px 16px;
  font-size: 17px;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	display: block;
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
  border-radius: 8px;
  padding: 14px 16px;
  padding: 20px;
  width: 70px;
  font-size: 20px;

  font-family: 'Work Sans', sans-serif !important;
}

.PhoneInputCountrySelect[disabled] {
  cursor: pointer;
}
.PhoneInputCountrySelect[readonly] {
	cursor: pointer;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[ type="file"] {
  display: none;
}

h1, h2, h3, h4, h5 {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
}
p, ul, li, span, a, div, input, select, textarea {
  font-family: 'Work Sans', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
}

@-webkit-keyframes spin { from { transform: rotate(0); } to { transform: rotate(360deg); }  }
   @-moz-keyframes spin { from { transform: rotate(0); } to { transform: rotate(360deg); }  }
     @-o-keyframes spin { from { transform: rotate(0); } to { transform: rotate(360deg); }  }
        @keyframes spin { from { transform: rotate(0); } to { transform: rotate(360deg); }  }

