td, th {
    border-collapse: collapse;
    border: 1px solid #AAAAAA;
    height: 48px;
}

.table {
    /* border-collapse: collapse; */
    margin-top: 50px;
    display: inline-block;
    max-width: 1287px;
    height: auto;
    position: relative;
}
